import React, { useState,useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Card from './screen/Card';
import WriteEditer from './screen/WriteEditer';
import UpdateEditer from './screen/WriteEditer/Update';
import Navigator from './components/Navigator';
import { AuthProvider } from './context/AuthContext';
import List from './screen/List';
import Bible from './screen/Bible';
import { Login, RegisterForm } from './components/Login';
import MyPage from './components/MyPage';
import CoramDeo from './screen/CoramDeo';
import VideoCast from './screen/VideoCast';
import Recitation from './screen/Recitation';
import PostViewer from './screen/PostViewer';
import { AudioProvider } from './context/AudioContext';
import GlobalPlayer from './components/GlobalPlayer';
import { useAuth } from "./context/AuthContext";
import PrivacyPolicy from './screen/privacy_policy.js';
import Upload from './screen/Upload';
const AppContainer = styled.div`
  min-height: 85vh;
`;

function App() {
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [isPlayModalOpen, setIsPlayModalOpen] = useState(false); // 재생 모달 상태 추가seState(false);
  const [albums, setAlbums] = useState([]);
  const { login ,isLoggedIn} = useAuth();

  const togglePlayer = () => {
    setIsPlayerVisible(!isPlayerVisible);
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        if(!isLoggedIn){
          return ;
        }
        const response = await fetch('/api/session');
        if (response.ok) {
          const userData = await response.json();
          login(userData);
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    fetchSessionData();
  }, [login]);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    // 'beforeinstallprompt' 이벤트 리스너를 추가합니다.
    const handleBeforeInstallPrompt = (e) => {
      // 이벤트를 방지하여 설치 프로세스를 제어할 수 있도록 합니다.
      e.preventDefault();
      // 이벤트 객체를 저장합니다.
      setDeferredPrompt(e);
      // 설치 가능 상태를 설정합니다.
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      return;
    }
    // 설치 프롬프트를 표시합니다.
    deferredPrompt.prompt();
    // 사용자가 설치를 수락했는지 여부를 확인합니다.
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
    // 프롬프트를 null로 초기화합니다.
    setDeferredPrompt(null);
    // 설치 가능 상태를 false로 설정합니다.
    setIsInstallable(false);
  };
  const getRandomColor = () => {
    const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39'];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  
  const fetchAlbums = () => {
    fetch('/api/my_albums', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        setAlbums(data.map(album => ({
          ...album,
          color: getRandomColor()
        })));
      })
      .catch(error => console.error('Error fetching albums:', error));
  };
  return (
    <AuthProvider>
      <AudioProvider>
        <div className="App">
          <header className="App-header">
            <Navigator handleInstallClick={handleInstallClick} isInstallable={isInstallable}/>
          </header>
          <AppContainer>
            <div>
              <Routes>
                <Route path="/" element={<Recitation isPlayModalOpen={isPlayModalOpen} setIsPlayModalOpen ={setIsPlayModalOpen} fetchAlbums={fetchAlbums}/>} key="Recitation" />
                <Route path="/song/:songId" element={<Recitation  isPlayModalOpen={isPlayModalOpen} setIsPlayModalOpen ={setIsPlayModalOpen}  fetchAlbums={fetchAlbums}/>} key="Recitation" />
                <Route path="/Card" element={<Card />} key="Home" />
                <Route path="/List" element={<List />} key="List" />
                <Route path="/Bible" element={<Bible />} key="Bible" />
                <Route path="/WriteEditer" element={<WriteEditer />} key="WriteEditer" />
                <Route path="/Update" element={<UpdateEditer />} key="UpdateEditer" />
                <Route path="/Register" element={<RegisterForm />} key="Register" />
                <Route path="/PostViewer" element={<PostViewer />} key="PostViewer" />
                <Route path="/Login" element={<Login />} key="Login" />
                <Route path="/MyPage" element={<MyPage />} key="MyPage" />
                <Route path="/CoramDeo" element={<CoramDeo />} key="CoramDeo" />
                <Route path="/VideoCast" element={<VideoCast />} key="VideoCast" />
                <Route path="/Up" element={<Upload />} key="Upload" />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} key="PrivacyPolicy" />
              </Routes>
            </div>
          </AppContainer>
          <GlobalPlayer isPlayModalOpen={isPlayModalOpen} setIsPlayModalOpen ={setIsPlayModalOpen} albums={albums} setAlbums={setAlbums} isVisible={isPlayerVisible} togglePlayer={togglePlayer} fetchAlbums={fetchAlbums}/>
        </div>
      </AudioProvider>
    </AuthProvider>
  );
}

export default App;