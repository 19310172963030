import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from "../../context/AuthContext";
import { FaPlus, FaMusic, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import AlbumSongs from './AlbumSongs';

const Container = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #1e88e5;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:hover {
    color: #1565c0;
  }
`;

const AlbumGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
`;

const AlbumCard = styled(motion.div)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const AlbumCover = styled.div`
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  aspect-ratio: auto;
  cursor:pointer;
`;

const AlbumIcon = styled(FaMusic)`
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.8);
`;

const AlbumInfo = styled.div`
  padding: 20px;
  flex-grow: 1;
  cursor: pointer;
`;

const AlbumTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const SongCount = styled.p`
  margin: 5px 0 0;
  font-size: 0.9rem;
  color: #666;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #e53935;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: #b71c1c;
  }
`;

const AddButton = styled(motion.button)`
  background-color: #1e88e5;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #1565c0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
`;

const ModalHeader = styled.h2`
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #1e88e5;
  }
`;

const ModalButton = styled(motion.button)`
  background-color: #1e88e5;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 10px;
  &:hover {
    background-color: #1565c0;
  }
`;

const LoginLink = styled(Link)`
  display: inline-block;
  background-color: #1e88e5;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1565c0;
  }
`;


export default function MyAlbum({ togglePlayer, setPlaylistVisible, albums , fetchAlbums}) {
  const { isLoggedIn, user } = useAuth();
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [newAlbum, setNewAlbum] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('albums'); // 'albums' or 'albumSongs'

  useEffect(() => {
    if (isLoggedIn) {
      fetchAlbums();
    }
  }, [isLoggedIn]);
  useEffect(() => {
  }, [albums]);

  const handleAddAlbum = () => {
    if (newAlbum.trim() !== '') {
      const newAlbumData = { userId: user.id, albumName: newAlbum }; // 필요한 경우 albumImgSrc 추가
      fetch('/api/my_albums', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newAlbumData),
      })
        .then(response => response.json())
        .then(data => {
          fetchAlbums(); // 앨범 목록 재조회
          setNewAlbum('');
          setIsModalOpen(false);
        })
        .catch(error => console.error('Error adding album:', error));
    }
  };

  const handleDeleteAlbum = (albumId) => {
    fetch(`/api/my_albums/${albumId}`, {
      credentials: 'include',
      method: 'DELETE'
    })
      .then(response => response.json())
      .then(data => {
        fetchAlbums(); // 앨범 목록 재조회
      })
      .catch(error => console.error('Error deleting album:', error));
  };

  const handleSelectAlbum = (albumId) => {
    setSelectedAlbum(albumId);
    setActiveTab('albumSongs');
  };

  return (
    <Container>
      <Title>마이 플레이리스트</Title>
      {isLoggedIn ? (
        <>
          {activeTab === 'albumSongs' && (
            <BackButton onClick={() => setActiveTab('albums')}>
              <FaArrowLeft /> 앨범으로 돌아가기
            </BackButton>
          )}

          {activeTab === 'albums' && (
            <>
              <AddButton
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsModalOpen(true)}
              >
                <FaPlus /> 앨범 추가
              </AddButton>
              <AlbumGrid>
                {albums.map((album, index) => (
                  <AlbumCard key={index} whileHover={{ y: -5 }}>
                    <AlbumCover color={album.color} onClick={() => handleSelectAlbum(album.id)}>
                      <AlbumIcon />
                    </AlbumCover>
                    <AlbumInfo onClick={() => handleSelectAlbum(album.id)}>
                      <AlbumTitle>{album.album_name}</AlbumTitle>
                      <SongCount>{album.song_count} 곡</SongCount>
                    </AlbumInfo>
                    <ButtonGroup>
                      <DeleteButton onClick={() => handleDeleteAlbum(album.id)}>
                        <FaTrash />
                      </DeleteButton>
                    </ButtonGroup>
                  </AlbumCard>
                ))}
              </AlbumGrid>
              <AnimatePresence>
                {isModalOpen && (
                  <ModalOverlay
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <ModalContent
                      initial={{ y: -50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 50, opacity: 0 }}
                    >
                      <ModalHeader>새 앨범 추가</ModalHeader>
                      <form>
                        <ModalInput
                          type="text"
                          value={newAlbum}
                          onChange={(e) => setNewAlbum(e.target.value)}
                          placeholder="앨범 제목"
                        />
                        <ModalButton
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={handleAddAlbum}
                        >
                          추가
                        </ModalButton>
                        <ModalButton
                          type="button"
                          onClick={() => setIsModalOpen(false)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          취소
                        </ModalButton>
                      </form>
                    </ModalContent>
                  </ModalOverlay>
                )}
              </AnimatePresence>
            </>
          )}

          {activeTab === 'albumSongs' && selectedAlbum && (
            <AlbumSongs albumId={selectedAlbum} />
          )}
        </>
      ) : (
        <LoginLink to='/login' onClick={() => { togglePlayer(); setPlaylistVisible(); }}>로그인하기</LoginLink>
      )}
    </Container>
  );
}