import React, { createRef, useState, useRef, useEffect, useMemo } from 'react';
import ImageResize from "quill-image-resize-module-react";
import ReactQuill,{Quill} from 'react-quill'; // ES6
import quillTable from 'quill-table';

import 'react-quill/dist/quill.snow.css';
import './style.css';
window.Quill = Quill;
let Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px']; // 사용 가능한 폰트 크기





Quill.register(Size, true);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/imageResize", ImageResize);
Quill.register('modules/table', quillTable.TableModule);

function HtmlEditer({Submit,RequestFrom,SelectedCategory,IsPublic,Base}) {
  const quillRef = useRef();
  const [인증,set인증] = useState();
  const [글제목 , set글제목] = useState(Base?.title);
  const [설교제목 , set설교제목] = useState(Base?.sermon_title);
  const [성경구절 , set성경구절] = useState(Base?.bible_verse);
  const [전송양식, set전송양식] = useState({  "item_id": Base?.item_id, "user_name": Base?.user_name,  "content": Base?.content,"create_date":Base?.create_date ,"category":Base?.category,"is_public":Base?.is_public,"sermon_title":Base?.sermon_title,"bible_verse":Base?.bible_verse});
  const [일자, set일자] = useState(Base?.create_date);  // 초기 날짜를 빈 문자열로 설정하여 날짜 선택이 기본으로 없도록 합니다.


  useEffect(() => { // 폼로드와 비슷한 개념
    const quill = quillRef.current.getEditor();
    setupDragAndDrop(quill);
    if (RequestFrom != undefined) {
      set전송양식({ ...전송양식, user_name:RequestFrom.user_name, title: 글제목, category: SelectedCategory?.NOTICE_CATEGORY, is_public: IsPublic });
    }
    else {
      set전송양식({ ...전송양식, category: SelectedCategory?.NOTICE_CATEGORY, is_public: IsPublic });
    }
  }, [RequestFrom,SelectedCategory,IsPublic]);

  const OnTextTitleChanged = (ev) => {
    set전송양식({ ...전송양식, title: ev.target.value })
    set글제목(ev.target.value);
  }
  const OnSermonTitleChanged = (ev) => {
    set전송양식({ ...전송양식, sermon_title: ev.target.value })
    set설교제목(ev.target.value);
  }
  const OnBibleVerseChanged = (ev) => {
    set전송양식({ ...전송양식, bible_verse: ev.target.value })
    set성경구절(ev.target.value);
  }
  const handleDateChange = (ev) => {
    set전송양식({ ...전송양식, create_date: ev.target.value })
    set일자(ev.target.value);
};

  const imageHandler = () => {
    const quill = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return; // Make sure a file was selected

      const formData = new FormData();
      const encodedFileName = encodeURIComponent(file.name);
      formData.append("img", file, encodedFileName);
      formData.append("userId", 'scribe');
      //formData.append('userId', Session.UserId);

      try {
          const response = await fetch('https://file.skynet.re.kr:3000' + '/upload/AttachFileUpload', {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              body: formData,
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const data = await response.json();
          const range = quill.getSelection()?.index;
          if (typeof (range) !== "number") return; // Ensure we have a valid range

          const url ='https://file.skynet.re.kr:2550'+ '/antsnest/AttachFile/' + data.filename;
          quill.insertEmbed(range.index, 'image', url, Quill.sources.USER);
          quill.formatText(range.index, 1, 'align', 'center'); // 이미지에 중앙 정렬 스타일 적용

          quill.setSelection(range.index + 1, Quill.sources.SILENT); // 커서 위치를 이미지 뒤로 이동


          console.log('사진업로드 완료:' + JSON.stringify(data));
      } catch (err) {
          console.log('업로드 오류:', err.message);
      }
  };
  }
  function handleTableActions(action) {
    const quill = quillRef.current.getEditor();
    const tableMdl=quill.getModule('table');
    switch (action) {
      case 'insertTable':
        tableMdl.insertTable(3, 3); // 3x3 테이블 삽입
        break;
      case 'insertRowAbove':
        tableMdl.insertRowAbove();
        break;
      case 'insertRowBelow':
        tableMdl.insertRowBelow();
        break;
      case 'insertColumnLeft':
        tableMdl.insertColumnLeft();
        break;
      case 'insertColumnRight':
        tableMdl.insertColumnRight();
        break;
      case 'deleteRow':
        tableMdl.deleteRow();
        break;
      case 'deleteColumn':
        tableMdl.deleteColumn();
        break;
      case 'deleteTable':
        tableMdl.deleteTable();
        break;
      default:
        break;
    }
  }
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline', 'clean'],
        [{ 'image': 'imageResize' }, 'code-block'],
        [{ 'table': ['insertTable', 'insertRowAbove', 'insertRowBelow', 'insertColumnLeft', 'insertColumnRight', 'deleteRow', 'deleteColumn', 'deleteTable'] }] // Ensure this adds a table
      ],
      handlers: {
        'image': imageHandler, // Consolidate image handler
        'insertTable': () => handleTableActions('insertTable'),
        'insertRowAbove': () => handleTableActions('insertRowAbove'),
        'insertRowBelow': () => handleTableActions('insertRowBelow'),
        'insertColumnLeft': () => handleTableActions('insertColumnLeft'),
        'insertColumnRight': () => handleTableActions('insertColumnRight'),
        'deleteRow': () => handleTableActions('deleteRow'),
        'deleteColumn': () => handleTableActions('deleteColumn'),
        'deleteTable': () => handleTableActions('deleteTable')
      },
      
    },
    'table':true,
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"]
    },
  }), []);

  function setupDragAndDrop(quill) {
    const editorContainer = quill.container;
  
    // Dragover 이벤트 리스너: 드래그 중인 요소가 드롭 가능한 영역 위에 있을 때
    editorContainer.addEventListener('dragover', function (e) {
      e.preventDefault();  // 기본 동작 방지
    });
  
    // Drop 이벤트 리스너: 드래그한 요소를 드롭 했을 때
    editorContainer.addEventListener('drop', function (e) {
      e.preventDefault();  // 기본 동작 방지
  
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file.type.startsWith('image/')) {
          insertImageFromFile(file, quill);
        }
      }
    });
  }

  function insertImageFromFile(file, quill) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const range = quill.getSelection();
      if (range) {
        quill.insertEmbed(range.index, 'image', reader.result);
        quill.setSelection(range.index + 1);
      }
    });
    reader.readAsDataURL(file);
  }

  const EditChange = (content, delta, source, editor) => {
    var Text = editor.getHTML();
    //console.log(JSON.stringify(Text));
    set전송양식({ ...전송양식, content: Text });
  }



  const Validate=()=>{
  // 제목과 내용이 비어있지 않은지 확인
    if (글제목 !== '' && 전송양식.content !== '') {
      Submit(전송양식);
    } else {
      // 필수 필드가 비어있는 경우 사용자에게 알림
      alert('제목과 내용은 필수로 입력해야 합니다.');
    }
  }
  return (
    <div>
      <main>
        <header style={{width:'100%' , display:'flex',flexDirection:'column'}}>
            <div  style={{width:'100%' , display:'flex',flexDirection:'row'}}>
                <input 
                        placeholder='제목을 입력하세요.' 
                        type='text'
                        style={{width:'100%' ,padding:'10px',borderWidth:'thin',borderColor:'silver'}}
                        value={글제목}
                        onChange={OnTextTitleChanged}>
                </input> 
            </div>
            <div  style={{width:'100%' , display:'flex',flexDirection:'row'}}>
              <input 
                      placeholder='설교제목' 
                      type='text'
                      style={{width:'50%' ,padding:'10px',borderWidth:'thin',borderColor:'silver'}}
                      value={설교제목}
                      onChange={OnSermonTitleChanged}>
              </input> 
              <input 
                      placeholder='성경구절' 
                      type='text'
                      style={{width:'30%' ,padding:'10px',borderWidth:'thin',borderColor:'silver'}}
                      value={성경구절}
                      onChange={OnBibleVerseChanged}>
              </input> 
              <input
                  type="date"
                  value={일자}
                  onChange={handleDateChange}
                  placeholder="일자선택"
                  style={{width:'20%' ,padding:'10px',borderWidth:'thin',borderColor:'silver'}}
              >
                </input>
            </div>
      
        </header>
        <article style={{textAlign:'left'}}>
        <ReactQuill
                    ref={quillRef}
                    modules={modules}
                    placeholder={'글을 입력하세요.'}
                    onChange={EditChange}
                    defaultValue={Base?.content}
        />
        </article>
      </main>
      <button type='button'
              onClick={() => Validate()}
              style={{display:'flex',justifyContent:'center',width:'100%',height:'5rem',alignItems:'center',fontSize:'1.3rem'}}>
              작 성 완 료
      </button>

    </div>
  );
}

export default HtmlEditer;