import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  background-color: #2d3748;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftMenu = styled.div`
  color: white;
  width: 20%;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  border-right: 1px solid #4a5568;
  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #4a5568;
    display: ${(props) => (props.showSidebar ? 'block' : 'none')}; /* 모바일에서 사이드바 표시 여부 */
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Tab = styled.button`
  flex: 1;
  padding: 10px;
  background-color: #4a5568;
  color: white;
  border: none;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background-color: #2d3748;
      border: 1px solid #4a5568;
      border-bottom: none;
    `}
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Content = styled.div`
  width: 80%;
  padding: 20px;
  color: white;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const BookItem = styled.li`
  color: black;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: #edf2f7;
  transition: background-color 0.3s ease;
  list-style-type: none;
  margin: 5px 0;

  &:hover {
    background-color: #cbd5e0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

const TitleContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const BookTitle = styled.label`
  color: white;
  font-size: 2.5rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ChapterItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #4a5568;
  border-radius: 5px;
  cursor: pointer;
`;

const VerseItem = styled.li`
  margin-bottom: 10px;
  padding: 10px;
  background-color: #4a5568;
  border-radius: 5px;
`;

const SearchForm = styled.form`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #4a5568;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #38a169;
  color: white;
  border: none;
  border-radius: 5px;
`;

const ResetButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const PaginationButton = styled.button`
  padding: 10px;
  cursor: pointer;
  background-color: #38a169;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
`;

const PageButton = styled.button`
  padding: 10px;
  cursor: pointer;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 5px;
`;

const ToggleButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: block;
    padding: 20px;
    font-size: 16px;
    background-color: #4a5568;
    color: white;
    border: none;
    cursor: pointer;
  }
`;

const Bible = () => {
  const [oldTestamentBooks, setOldTestamentBooks] = useState([]);
  const [newTestamentBooks, setNewTestamentBooks] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [chapters, setChapters] = useState({});
  const [currentChapter, setCurrentChapter] = useState(1);
  const [localSearchResults, setLocalSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('oldTestament');
  const [showSidebar, setShowSidebar] = useState(true); // 사이드바 표시 상태 추가
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedChapters, setExpandedChapters] = useState([]);
  const chaptersPerPage = 10;
  const verseRefs = useRef({});

  useEffect(() => {
    fetch('/api/bible_books')
      .then(response => response.json())
      .then(data => {
        setOldTestamentBooks(data.oldTestamentBooks);
        setNewTestamentBooks(data.newTestamentBooks);
        const firstBook = data.oldTestamentBooks[0];
        setSelectedBook(firstBook);
        fetch(`/api/bible_chapters?book=${firstBook.book}`)
          .then(response => response.json())
          .then(data => {
            setChapters(data.chapters);
            verseRefs.current = {};
            setCurrentChapter(1);
            setCurrentPage(1);
            setShowSidebar(false);
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }, []);
  const handleBookClick = (book) => {
    setSelectedBook(book);
    fetch(`/api/bible_chapters?book=${book.book}`)
      .then(response => response.json())
      .then(data => {
        setChapters(data.chapters);
        verseRefs.current = {};
        setCurrentChapter(1);
        setCurrentPage(1);
        setShowSidebar(false); // 책을 클릭하면 사이드바를 숨기고 콘텐츠를 표시
      })
      .catch(err => console.error(err));
  };

  const handleChapterClick = (chapter) => {
    setCurrentChapter(chapter);
    setExpandedChapters(prev => 
      prev.includes(chapter) ? prev.filter(ch => ch !== chapter) : [...prev, chapter]
    );
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (!chapters[currentChapter]) return;

    const results = chapters[currentChapter].filter(verse =>
      verse.text.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setLocalSearchResults(results);
  };

  const handleResetSearch = () => {
    setSearchQuery('');
    setLocalSearchResults([]);
  };

  useEffect(() => {
    if (selectedBook && chapters[currentChapter]) {
      const firstVerse = chapters[currentChapter][0];
      if (firstVerse && verseRefs.current[firstVerse.verse]) {
        verseRefs.current[firstVerse.verse].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [chapters, currentChapter]);

  const renderBooks = (books) => {
    return (
      <Section>
        {books.map((book, idx) => (
          <BookItem key={idx} onClick={() => handleBookClick(book)}>
            {book.book}
          </BookItem>
        ))}
      </Section>
    );
  };

  const renderChapters = () => {
    if (!selectedBook || Object.keys(chapters).length === 0) return null;

    const chapterKeys = Object.keys(chapters);
    const totalPages = Math.ceil(chapterKeys.length / chaptersPerPage);

    const startIndex = (currentPage - 1) * chaptersPerPage;
    const currentChapters = chapterKeys.slice(startIndex, startIndex + chaptersPerPage);

    return (
      <div>
        <TopContainer>
          <TitleContainer>
            <BookTitle>{selectedBook.book}</BookTitle>
          </TitleContainer>
          <Pagination>
            <PaginationButton onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
              이전
            </PaginationButton>
            {Array.from({ length: totalPages }, (_, idx) => (
              <PageButton
                key={idx}
                onClick={() => setCurrentPage(idx + 1)}
                style={idx + 1 === currentPage ? { backgroundColor: 'green' } : {}}
              >
                {idx + 1}
              </PageButton>
            ))}
            <PaginationButton onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
              다음
            </PaginationButton>
          </Pagination>
        </TopContainer>
        <hr />
        <SearchForm onSubmit={handleSearchSubmit}>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="검색어를 입력하세요"
          />
          <SearchButton type="submit">검색</SearchButton>
          <ResetButton type="button" onClick={handleResetSearch}>검색 취소</ResetButton>
        </SearchForm>
        <ul style={{ listStyleType: 'none', textAlign: 'left', padding: 0 }}>
          {localSearchResults.length > 0 ? (
            localSearchResults.map((verse, idx) => (
              <VerseItem key={idx}>
                {verse.verse}절: {verse.text}
              </VerseItem>
            ))
          ) : (
            currentChapters.map((chapter, idx) => (
              <li key={idx}>
                <ChapterItem onClick={() => handleChapterClick(parseInt(chapter))}>
                  {chapter}장
                </ChapterItem>
                {expandedChapters.includes(parseInt(chapter)) && (
                  <ul style={{ listStyleType: 'none', textAlign: 'left', paddingLeft: '20px' }}>
                    {chapters[chapter].map((verse, idx) => (
                      <VerseItem key={idx} ref={el => verseRefs.current[verse.verse] = el}>
                        {verse.verse}절: {verse.text}
                      </VerseItem>
                    ))}
                  </ul>
                )}
              </li>
            ))
          )}
        </ul>
      </div>
    );
  };

  return (
    <Container>
      <ToggleButton onClick={() => setShowSidebar(!showSidebar)}>
        {showSidebar ? '콘텐츠 보기' : '책선택'}
      </ToggleButton>
      <LeftMenu showSidebar={showSidebar}>
        <Tabs>
          <Tab
            onClick={() => { setActiveTab('oldTestament'); setCurrentPage(1); }}
            active={activeTab === 'oldTestament'}
          >
            구약 성경
          </Tab>
          <Tab
            onClick={() => { setActiveTab('newTestament'); setCurrentPage(1); }}
            active={activeTab === 'newTestament'}
          >
            신약 성경
          </Tab>
        </Tabs>
        {activeTab === 'oldTestament' && renderBooks(oldTestamentBooks)}
        {activeTab === 'newTestament' && renderBooks(newTestamentBooks)}
      </LeftMenu>
      <Content>
        {renderChapters()}
      </Content>
    </Container>
  );
};

export default Bible;
