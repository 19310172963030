import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlay, FaTrash } from 'react-icons/fa';
import { useAudio } from "../../context/AudioContext";

const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const SongList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const SongItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const SongInfo = styled.div`
  display: flex;
  align-items: center;
`;

const SongTitle = styled.span`
  margin-left: 10px;
  font-size: 1rem;
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  color: #1e88e5;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;

  &:hover {
    color: #1565c0;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #e53935;
  cursor: pointer;
  font-size: 1.2rem;

  &:hover {
    color: #b71c1c;
  }
`;

const AddAllButton = styled.button`
  background: none;
  border: none;
  color: #1e88e5;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 10px;

  &:hover {
    color: #1565c0;
  }
`;

const AlbumSongs = ({ albumId }) => {
  const [songs, setSongs] = useState([]);
  const { handlePlayNow, handleAddToPlaylist } = useAudio();

  useEffect(() => {
    fetch(`/api/myalbum_songs?albumId=${albumId}`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        setSongs(data);
      })
      .catch(error => console.error('Error fetching songs:', error));
  }, [albumId]);

  const handleDeleteSong = (songId) => {
    fetch(`/api/album_songs_delete/${songId}`, {
      credentials: 'include',
      method: 'DELETE'
    })
      .then(response => response.json())
      .then(data => {
        setSongs(songs.filter(song => song.id !== songId));
      })
      .catch(error => console.error('Error deleting song:', error));
  };

  const playSong = (songId) => {
    fetch(`/api/playsong/${songId}`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(song => {
        handlePlayNow(song);
      })
      .catch(error => console.error('Error playing song:', error));
  };
  const playListAddSong = (songId) => {
    fetch(`/api/playsong/${songId}`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(song => {
        handleAddToPlaylist(song);
      })
      .catch(error => console.error('Error playing song:', error));
  };

  const addAllToPlaylist = () => {
    songs.forEach(song => {
        playListAddSong(song.id);
    });

    if (songs.length > 0) {
      playSong(songs[0].id);
    }
  };

  return (
    <Container>
      <Title>앨범 음악</Title>
      <AddAllButton onClick={addAllToPlaylist}>
        전체 재생
      </AddAllButton>
      <SongList>
        {songs.map((song, index) => (
          <SongItem key={index}>
            <SongInfo>
              <PlayButton onClick={() => playSong(song.id)}>
                <FaPlay />
              </PlayButton>
              <SongTitle>{song.song_name}</SongTitle>
            </SongInfo>
            <DeleteButton onClick={() => handleDeleteSong(song.id)}>
              <FaTrash />
            </DeleteButton>
          </SongItem>
        ))}
      </SongList>
    </Container>
  );
};

export default AlbumSongs;