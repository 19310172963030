import React, { useRef, useState, useEffect } from 'react';
import movie1 from '../../movie/play1.mp4';
import movie2 from '../../movie/play2.mp4';
import movie3 from '../../movie/play3.mp4';
import movie4 from '../../movie/play4.mp4';

function Sidebar({ videos, onSelectVideo }) {
  return (
    <div style={{
      width: '200px',
      float: 'left',
      padding: '10px',
      borderRight: '1px solid #ddd',
      backgroundColor: '#f4f4f4',
      height: '100vh',
      boxShadow: '2px 0 5px rgba(0,0,0,0.1)'
    }}>
      <h3 style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '18px',
        color: '#333',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px'
      }}>재생목록</h3>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {videos.map((video, index) => (
          <li key={index} style={{ margin: '10px 0' }}>
            <button
              onClick={() => onSelectVideo(video.src, video.type)}
              style={{
                width: '100%',
                padding: '10px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: '#007BFF',
                color: 'white',
                cursor: 'pointer',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
                transition: 'background-color 0.3s ease'
              }}
              onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#007BFF'}
            >
              {video.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function VideoPlayer({ src, type }) {
  const videoRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [currentSrc, setCurrentSrc] = useState(src);
  const [currentType, setCurrentType] = useState(type);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRepeating, setIsRepeating] = useState(false);
  const [duration, setDuration] = useState(0);
  const handlePlay = () => {
    videoRef.current.play();
  };

  const handlePause = () => {
    videoRef.current.pause();
  };

  const handleStop = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const handleTimeUpdate = () => {
    const current = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    setProgress((current / duration) * 100);
    setCurrentTime(current);
  };

  const handleProgressBarChange = (e) => {
    const duration = videoRef.current.duration;
    const newTime = (e.target.value / 100) * duration;
    videoRef.current.currentTime = newTime;
  };

  const handleSelectVideo = (src, type) => {
    setCurrentSrc(src);
    setCurrentType(type);
    setProgress(0);
  };
  const handleRepeatToggle = () => {
    setIsRepeating(!isRepeating);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [currentSrc, currentType]);

  const videos = [
    { title: 'Video 1', src: movie1, type: 'video/mp4' },
    { title: 'Video 2', src: movie2, type: 'video/mp4' },
    { title: 'Video 3', src: movie3, type: 'video/mp4' },
    { title: 'Video 4', src: movie4, type: 'video/mp4' },
  ];

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar videos={videos} onSelectVideo={handleSelectVideo} />
      <div style={{ marginLeft: '220px' }}>
        <video
          ref={videoRef}
          width="840"
          height="560"
          controls={false}
          onTimeUpdate={handleTimeUpdate}
        >
          <source key={currentSrc} src={currentSrc} type={currentType} />
          Your browser does not support the video tag.
        </video>
        <VideoController
          onPlay={handlePlay}
          onPause={handlePause}
          onStop={handleStop}
          onRepeatToggle={handleRepeatToggle}
          isRepeating={isRepeating}
          currentTime={currentTime}
          duration={duration}
        />
        <div>
          <input
            type="range"
            value={progress}
            onChange={handleProgressBarChange}
            style={{ width: '640px' }}
          />
        </div>
      </div>
    </div>
  );
}
function VideoController({ onPlay, onPause, onStop, onRepeatToggle, isRepeating, currentTime, duration }) {
  return (
    <div>
      <button onClick={onPlay}>Play</button>
      <button onClick={onPause}>Pause</button>
      <button onClick={onStop}>Stop</button>
      <button onClick={onRepeatToggle} style={{ backgroundColor: isRepeating ? 'green' : 'red' }}>
        {isRepeating ? 'Repeat On' : 'Repeat Off'}
      </button>
      <div>Current Time: {formatTime(currentTime)} / {formatTime(duration)}</div>
    </div>
  );
}

function formatTime(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}