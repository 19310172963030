import React,{useState,useEffect,useContext} from 'react';
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './style.css';


export default function CoramDeo(props){
  let navigate = useNavigate();
  const location = useLocation();

const [get_DataList,set_DataList] = useState([
    { id: 1, title: "Post 1", content: "This is the first post content." },
    { id: 2, title: "Post 2", content: "This is the second post content." },
    { id: 3, title: "Post 3", content: "This is the third post content." }
  ]);

  const { login } = useAuth();
  const fetchSessionData = async () => {
    try {
      const response = await fetch(`api/session`);
      if (response.ok) {
        const userData = await response.json();
        // 전역 상태에 사용자 정보 저장 (예: Context API, Redux 사용)
        login(userData);
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
    }
  };
  useEffect(() => {
    fetchSessionData();
  }, [login]);
  useEffect(() => {
    getItemList();
  },[location])

  const getItemList = async (userId) => {
    // 여기에 비동기 통신 로직 구현
    try {
      const response = await fetch(`api/req_itemList`, {
        headers: {
          Accept: "application / json",
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_DataList(data.posts); // 서버에서 받은 데이터로 상태를 업데이트합니다.

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
        return (
          <div style={{justifyContent:'center',display:'flex',flexDirection:'column',width:'100%',color:'#fff'}}>
            <h1 className="title">오직 하나님 앞에서</h1>
            <h2 className="subtitle">神 前 意 識</h2>
            <Instructions />
            <AttendanceTable />
          </div>
        );
};
const Instructions = () => {
  return (
    <div style={{color:'#fff'}} className="instructions">
      <h3 className="section-title">기록요령</h3>
      <ul className="list">
        <li>출석 / 출석(O), 지각(△) (사유기재)</li>
        <li>주중 기도 / 20분 이상 (O), 20분 이하 (△)</li>
        <li>Q.T. / 맞추로 기록 / 예: (5)</li>
        <li>D명 Q.T. 과제 / O, X 로 기록</li>
        <li>다락방 / O, X 로 기록</li>
        <li>성경암송, 예습, 독서율, 생활숙제, 봉사 / O, △, X 로</li>
        <li>예 배 / 주일(O), 수요(O), 금요(O), 새벽(3) / 새벽예배는 횟수로 기록</li>
      </ul>
    </div>
  );
}

const AttendanceTable = () => {
  return (
    <table style={{color:'#000'}} className="attendance-table">
      <thead>
        <tr>
          <th>번호</th>
          <th>이름</th>
          <th>출석</th>
          <th>주중기도</th>
          <th>Q.T.</th>
          <th>D명 Q.T. 과제</th>
          <th>다락방</th>
          <th>성경암송</th>
          <th>예습</th>
          <th>독서율</th>
          <th>생활숙제</th>
          <th>봉사</th>
          <th>예배드리는 삶</th>
          <th>비고</th>
        </tr>
      </thead>
      <tbody>
        {/* Repeat this row as needed */}
        <tr style={{color:'#fff'}}>
          <td>1</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {/* Repeat ends */}
      </tbody>
    </table>
  );
}


  
  
  
  
  
  





