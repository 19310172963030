import React from 'react';
import styled from 'styled-components';

const PlayModal = ({ isOpen, onClose, onPlayClick ,setModalTrack,modalTrack}) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>오디오 재생</h2>
        <p>브라우저 정책에 의해 자동 재생이 차단되었습니다. </p>
        <p> 아래 버튼을 눌러 직접 재생하세요.</p>
        <button onClick={()=>onPlayClick(modalTrack)}>재생</button>
        <button onClick={onClose}>닫기</button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default PlayModal;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #45a049;
    }
  }
`;