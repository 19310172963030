import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.2rem;
  color: #0073e6;
  text-align: center;
  margin-bottom: 24px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
  margin-bottom: 12px;
  border-left: 4px solid #0073e6;
  padding-left: 10px;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 16px;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 16px;
`;

const ListItem = styled.li`
  font-size: 1rem;
  margin-bottom: 8px;
`;

const ContactInfo = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 16px;
`;

const ContactItem = styled.li`
  margin-bottom: 8px;
  font-size: 1rem;
`;

const Link = styled.a`
  color: #0073e6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Date = styled.p`
  font-size: 0.9rem;
  color: #666;
  text-align: right;
  margin-top: 24px;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Title>개인정보 처리방침</Title>

      <SectionTitle>1. 개인정보의 수집 및 이용 목적</SectionTitle>
      <Paragraph>
        저희 스카이 스크라이브는 무료로 제공되는 성경 웹플레이어 서비스로, 비영리 목적으로 운영됩니다. 저희는 최소한의
        개인정보만을 수집하며, 그 목적은 다음과 같습니다:
      </Paragraph>
      <List>
        <ListItem>사용자 식별 및 기본적인 서비스 제공</ListItem>
        <ListItem>사용자와의 원활한 소통 및 고객 지원</ListItem>
      </List>
      <Paragraph>저희는 사용자의 개인정보를 상업적 목적으로 사용하지 않습니다.</Paragraph>

      <SectionTitle>2. 수집하는 개인정보 항목</SectionTitle>
      <Paragraph>
        저희가 수집하는 개인정보는 서비스 제공을 위해 필요한 최소한의 정보로 한정됩니다. 수집 항목은 다음과 같습니다:
      </Paragraph>
      <List>
        <ListItem>
          <strong>필수 정보</strong>: 이름, 이메일 주소 (회원 가입 및 인증을 위해 필요)
        </ListItem>
        <ListItem>
          <strong>자동 수집 정보</strong>: IP 주소, 쿠키 정보, 서비스 사용 기록 (서비스의 안정성 확보 및 사용자 경험 향상을 위해 자동 수집)
        </ListItem>
      </List>

      <SectionTitle>3. 개인정보의 보유 및 이용 기간</SectionTitle>
      <Paragraph>
        저희는 사용자가 서비스를 이용하는 동안 개인정보를 보유하며, 서비스 탈퇴 시 해당 정보를 지체 없이 파기합니다.
        다만, 법령에 의해 보관해야 하는 정보는 해당 법령에 따라 일정 기간 동안 보유됩니다.
      </Paragraph>

      <SectionTitle>4. 개인정보의 제3자 제공</SectionTitle>
      <Paragraph>
        저희는 사용자의 개인정보를 제3자에게 제공하지 않습니다. 다만, 법적 요구 사항이 있을 경우나 사용자의 명시적인
        동의가 있을 경우에 한해 예외적으로 제공할 수 있습니다.
      </Paragraph>

      <SectionTitle>5. 개인정보의 처리 위탁</SectionTitle>
      <Paragraph>
        저희는 개인정보 처리 업무를 외부 업체에 위탁하지 않습니다. 만약 위탁이 필요해질 경우, 사용자의 사전 동의를
        받으며, 위탁 대상 및 내용에 대해 투명하게 공개하겠습니다.
      </Paragraph>

      <SectionTitle>6. 개인정보의 보호</SectionTitle>
      <Paragraph>
        저희는 사용자의 개인정보를 보호하기 위해 합리적인 기술적 및 관리적 보안 조치를 취하고 있습니다. 이를 통해
        개인정보가 무단으로 접근되거나 유출되지 않도록 노력하고 있습니다.
      </Paragraph>

      <SectionTitle>7. 사용자의 권리</SectionTitle>
      <Paragraph>
        사용자는 언제든지 본인의 개인정보에 대한 조회, 수정, 삭제를 요청할 수 있으며, 회원 탈퇴 시 모든 개인정보는
        즉시 파기됩니다. 개인정보와 관련한 권리 행사는 아래의 연락처를 통해 요청하실 수 있습니다.
      </Paragraph>

      <SectionTitle>8. 쿠키 사용</SectionTitle>
      <Paragraph>
        저희는 사용자 편의를 위하여 쿠키를 사용합니다. 사용자는 브라우저 설정을 통해 쿠키 저장을 거부하거나 삭제할 수
        있습니다. 쿠키 거부 시 일부 서비스 이용에 제한이 있을 수 있습니다.
      </Paragraph>

      <SectionTitle>9. 개인정보 처리방침의 변경</SectionTitle>
      <Paragraph>
        저희는 법령 변경이나 서비스 정책의 변경에 따라 개인정보 처리방침을 수정할 수 있습니다. 변경된 사항은 본 페이지를 통해 고지되며, 중요한 변경 사항은 사용자에게 별도로 안내될 예정입니다.
      </Paragraph>

      <SectionTitle>10. 문의사항</SectionTitle>
      <Paragraph>
        개인정보 처리방침에 대한 문의사항이 있거나 개인정보와 관련된 요청 사항이 있을 경우 아래의 연락처로 문의해
        주시기 바랍니다:
      </Paragraph>
      <ContactInfo>
        <ContactItem>
          <strong>이메일</strong>: <Link href="mailto:admin@antsnest.co.kr">admin@antsnest.co.kr</Link>
        </ContactItem>
        <ContactItem>
          <strong>전화번호</strong>: <Link href="tel:07077970814">070-7797-0814</Link>
        </ContactItem>
      </ContactInfo>

      <Date>최종 수정일: 2024년 08월 17일</Date>
    </Container>
  );
};

export default PrivacyPolicy;
