import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import {
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
Modal.setAppElement("#root");

export default function Upload() {
  const [songs, setSongs] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSong, setCurrentSong] = useState({});
  const [lyrics, setLyrics] = useState([{ time: "", text: "" }]);
  const [currentPage, setCurrentPage] = useState(1);
  const [songsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    fetchSongs();
    fetchAlbums();
  }, []);

  const fetchSongs = async () => {
    try {
      const response = await fetch("/api/getSongs", { credentials: "include" });
      const data = await response.json();
      console.log("Fetched songs data:", data);
      setSongs(data);
    } catch (error) {
      console.error("Error fetching songs:", error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await fetch("/api/getAlbums", {
        credentials: "include",
      });
      const data = await response.json();
      console.log("Fetched albums data:", data);
      setAlbums(data);
    } catch (error) {
      console.error("Error fetching albums:", error);
    }
  };

  const openModal = (song = {}) => {
    setCurrentSong(song);
    setLyrics(song.lyrics || [{ time: "", text: "" }]);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentSong({});
    setLyrics([{ time: "", text: "" }]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentSong({ ...currentSong, [name]: value });
  };

  const handleLyricsChange = (index, field, value) => {
    const newLyrics = [...lyrics];
    newLyrics[index][field] = value;
    setLyrics(newLyrics);
  };

  const addLyricField = () => {
    setLyrics([...lyrics, { time: "", text: "" }]);
  };

  const removeLyricField = (index) => {
    const newLyrics = lyrics.filter((_, i) => i !== index);
    setLyrics(newLyrics);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "songFile") {
      setCurrentSong({ ...currentSong, songFile: files[0] });
    } else if (name === "imageFile") {
      setCurrentSong({ ...currentSong, imageFile: files[0] });
    }
  };

  const handleAlbumIdChange = (e) => {
    const { value } = e.target;
    const selectedAlbum = albums.find(
      (album) => album.id === parseInt(value)
    );
    const albumTitle = selectedAlbum ? selectedAlbum.title : "";
    setCurrentSong({
      ...currentSong,
      album_id: parseInt(value),
      src: albumTitle,
      image: albumTitle,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const songWithLyrics = { ...currentSong, lyrics: JSON.stringify(lyrics) };
    alert(JSON.stringify(songWithLyrics));
    const formData = new FormData();
  // FormData에 데이터를 추가하고 콘솔에 출력
  Object.keys(songWithLyrics).forEach((key) => {
    if (songWithLyrics[key] !== undefined && songWithLyrics[key] !== null) {
      formData.append(key, songWithLyrics[key]);
    } else {
      console.log(`Key ${key} is undefined or null`);
    }
  });
    if (currentSong.songFile) formData.append("songFile", currentSong.songFile);
    if (currentSong.imageFile)
      formData.append("imageFile", currentSong.imageFile);

    const url = currentSong.id
      ? `/upload/updateSong/${currentSong.id}`
      : "/upload/addSong";
    const method = currentSong.id ? "PUT" : "POST";
    
   // FormData의 내용을 문자열로 변환
  let formDataString = '';
  for (let [key, value] of formData.entries()) {
    formDataString += `${key}: ${value}\n`;
  }



    try {
      await fetch(url, {
        method: method,
        body: formData,
        credentials: "include",
      });
      fetchSongs();
      closeModal();
    } catch (error) {
      console.error("Error updating song:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말 이 곡을 삭제하시겠습니까?")) {
      try {
        await fetch(`/upload/deleteSong/${id}`, {
          method: "DELETE",
          credentials: "include",
        });
        fetchSongs();
      } catch (error) {
        console.error("Error deleting song:", error);
      }
    }
  };

  const groupSongsById = (songs) => {
    const grouped = [];
    const songMap = {};

    songs.forEach((song) => {
      if (!songMap[song.id]) {
        songMap[song.id] = {
          id: song.id,
          title: song.title,
          album_id: song.album_id,
          src: song.src,
          image: song.image,
          display: song.display,
          created_at: song.created_at,
          album_title: song.album_title,
          album_name: song.album_name,
          album_img_src: song.album_img_src,
          lyrics: [],
        };
        grouped.push(songMap[song.id]);
      }
      if (song.time && song.text) {
        songMap[song.id].lyrics.push({ time: song.time, text: song.text });
      }
    });

    return grouped;
  };

  const groupedSongs = groupSongsById(songs);
  const filteredSongs = groupedSongs.filter(
    (song) =>
      song.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (song.display && song.display.toLowerCase().includes(searchQuery.toLowerCase()))
  );
  const indexOfLastSong = currentPage * songsPerPage;
  const indexOfFirstSong = indexOfLastSong - songsPerPage;
  const currentSongs = filteredSongs.slice(indexOfFirstSong, indexOfLastSong);
  const totalPages = Math.ceil(filteredSongs.length / songsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container>
      <Header>
        <Title>Songs List</Title>
        <SearchInput
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <AddButton onClick={() => openModal()}>
          <FaPlus />
        </AddButton>
      </Header>
      {Array.isArray(songs) ? (
        <>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Album ID</th>
                  <th>Src</th>
                  <th>Image</th>
                  <th>Display</th>
                  <th>Created At</th>
                  <th>Album Title</th>
                  <th>Album Name</th>
                  <th>Album Image Src</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentSongs.map((song) => (
                  <tr key={song.id}>
                    <td>{song.id}</td>
                    <td>{song.title}</td>
                    <td>{song.album_id}</td>
                    <td>{song.src}</td>
                    <td>{song.image}</td>
                    <td>{song.display}</td>
                    <td>{song.created_at}</td>
                    <td>{song.album_title}</td>
                    <td>{song.album_name}</td>
                    <td>{song.album_img_src}</td>
                    <td>
                      <EditButton onClick={() => openModal(song)}>
                        <FaEdit />
                      </EditButton>
                      <DeleteButton onClick={() => handleDelete(song.id)}>
                        <FaTrash />
                      </DeleteButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
          <Pagination>
            <PaginationButton
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </PaginationButton>
            {[...Array(totalPages).keys()].map((number) => (
              <PaginationButton
                key={number + 1}
                onClick={() => paginate(number + 1)}
                active={currentPage === number + 1}
              >
                {number + 1}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </PaginationButton>
          </Pagination>
        </>
      ) : (
        <NoSongs>No songs found.</NoSongs>
      )}

      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <ModalContent>
          <ModalHeader>
            <h2>{currentSong.id ? "Edit Song" : "Add New Song"}</h2>
            <CloseButton onClick={closeModal}>&times;</CloseButton>
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Title:</Label>
              <Input
                type="text"
                name="title"
                value={currentSong.title || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Album:</Label>
              <Select
                name="album_id"
                value={currentSong.album_id || ""}
                onChange={handleAlbumIdChange}
              >
                <option value="">Select an album</option>
                {albums.map((album) => (
                  <option key={album.id} value={album.id}>
                    {album.album_name}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Source:</Label>
              <Input
                type="text"
                name="src"
                value={currentSong.src || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Image:</Label>
              <Input
                type="text"
                name="image"
                value={currentSong.image || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Display:</Label>
              <Input
                type="text"
                name="display"
                value={currentSong.display || ""}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label>Lyrics:</Label>
              {lyrics.map((lyric, index) => (
                <LyricInputGroup key={index}>
                  <Input
                    type="text"
                    placeholder="Time"
                    value={lyric.time}
                    onChange={(e) =>
                      handleLyricsChange(index, "time", e.target.value)
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Lyrics"
                    value={lyric.text}
                    onChange={(e) =>
                      handleLyricsChange(index, "text", e.target.value)
                    }
                  />
                  <RemoveLyricButton onClick={() => removeLyricField(index)}>
                    <FaTrash />
                  </RemoveLyricButton>
                </LyricInputGroup>
              ))}
              <AddLyricButton type="button" onClick={addLyricField}>
                Add Lyric
              </AddLyricButton>
            </FormGroup>
            {!currentSong.id && (
              <>
                <FormGroup>
                  <Label>Song File:</Label>
                  <Input
                    type="file"
                    name="songFile"
                    onChange={handleFileChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Image File:</Label>
                  <Input
                    type="file"
                    name="imageFile"
                    onChange={handleFileChange}
                  />
                </FormGroup>
              </>
            )}
            <ButtonGroup>
              <SubmitButton type="submit">Save</SubmitButton>
              <CancelButton type="button" onClick={closeModal}>
                Cancel
              </CancelButton>
            </ButtonGroup>
          </Form>
        </ModalContent>
      </StyledModal>
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  color: #333;
  font-size: 24px;
`;

const SearchInput = styled.input`
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const AddButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  tr:hover {
    background-color: #e8e8e8;
  }
`;

const EditButton = styled.button`
  background-color: #008cba;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007b9a;
  }
`;

const DeleteButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const NoSongs = styled.p`
  color: #666;
  font-style: italic;
`;

const StyledModal = styled(Modal)`
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const LyricInputGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

const RemoveLyricButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const AddLyricButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const CancelButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background-color: ${(props) => (props.active ? "#4caf50" : "#f1f1f1")};
  color: ${(props) => (props.active ? "white" : "black")};
  border: 1px solid #ddd;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: #ddd;
  }

  &:disabled {
    color: #999;
    cursor: not-allowed;
  }
`;