import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Content = styled(motion.div)`
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
`;

const Header = styled.h2`
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
`;

const ModalButton = styled(motion.button)`
  background-color: #1e88e5;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 10px;
  &:hover {
    background-color: #1565c0;
  }
`;

const LoginButton = styled(Link)`
  display: inline-block;
  background-color: #1e88e5;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 30px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  text-align: center;

  &:hover {
    background-color: #1565c0;
  }
`;

const AlbumList = styled.ul`
  list-style: none;
  padding: 0;
`;

const AlbumItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const RadioButton = styled.input`
  margin-left: 10px;
`;

const PlaylistModal = ({ isOpen, onClose, isLoggedIn, selectedAlbum, setSelectedAlbum, onConfirm, song }) => {
  const [myAlbums, setMyAlbums] = useState([]);

  useEffect(() => {
    if (isOpen && isLoggedIn) {
      fetch('/api/my_albums', { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
          setMyAlbums(data);
        })
        .catch(error => console.error('Error fetching my albums:', error));
    }
  }, [isOpen, isLoggedIn]);

  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Content
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
          >
            <Header>앨범에 추가</Header>
            {isLoggedIn ? (
              <>
                <p>{song.display}를 앨범에 추가합니다.</p>
                <AlbumList>
                  {myAlbums.map(album => (
                    <AlbumItem key={album.id}>
                      {album.album_name}
                      <RadioButton
                        type="radio"
                        name="album"
                        value={album.id}
                        checked={selectedAlbum === album.id}
                        onChange={() => setSelectedAlbum(album.id)}
                      />
                    </AlbumItem>
                  ))}
                </AlbumList>
                <ModalButton
                  type="button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => onConfirm(song)}
                >
                  확인
                </ModalButton>
                <ModalButton
                  type="button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClose}
                >
                  취소
                </ModalButton>
              </>
            ) : (
              <LoginButton to="/login">로그인하기</LoginButton>
            )}
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export default PlaylistModal;