import React, { useState, useEffect } from 'react';
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import VideoPlayer from '../../components/VideoPlayer';
import movie from '../../movie/play1.mp4';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f0f4f8;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ButtonLink = styled.button`
  text-decoration: none;
  color: #fff;
  background-color: #46b5bd;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #369ca0;
  }
`;

const VideoContainer = styled.div`
  width: 80%;
  margin-top: 20px;
`;

const VideoTitle = styled.h2`
  color: #333;
  text-align: center;
  margin: 20px 0;
`;

const VideoContent = styled.p`
  color: #666;
  text-align: center;
  margin-bottom: 20px;
`;

export default function VideoCast() {
  let navigate = useNavigate();
  const location = useLocation();

  const [get_DataList, set_DataList] = useState([
    { id: 1, title: "Post 1", content: "This is the first post content." },
    { id: 2, title: "Post 2", content: "This is the second post content." },
    { id: 3, title: "Post 3", content: "This is the third post content." }
  ]);

  const { login } = useAuth();
  const fetchSessionData = async () => {
    try {
      const response = await fetch(`api/session`);
      if (response.ok) {
        const userData = await response.json();
        login(userData);
      }
    } catch (error) {
      console.error('Error fetching session data:', error);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, [login]);

  useEffect(() => {
    getItemList();
  }, [location]);

  const getItemList = async () => {
    try {
      const response = await fetch(`api/req_itemList`, {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_DataList(data.posts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonLink onClick={() => navigate('/')}>홈</ButtonLink>
        <div>
          <ButtonLink onClick={() => navigate('/List')}>리스트</ButtonLink>
        </div>
      </Header>
      <VideoContainer>
        <VideoTitle>비디오 타이틀</VideoTitle>
        <VideoContent>여기에 비디오에 대한 설명을 추가하세요.</VideoContent>
        <VideoPlayer src={movie}></VideoPlayer>
      </VideoContainer>
    </Container>
  );
}
