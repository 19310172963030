import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAudio } from '../../context/AudioContext';
import MyAlbum from '../MyAlbum';
import { FaChevronUp, FaChevronDown, FaListUl, FaTrashAlt, FaRandom } from 'react-icons/fa';
import emptyPlaylistImage from '../../images/empty_playlist.webp';

const PlayerContainer = styled.div`
  position: fixed;
  bottom: ${(props) => (props.isVisible ? '0' : '-250px')};
  height: 160px;
  min-width: 800px;
  background-color: #edf2f7;
  color: black;
  transition: bottom 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  padding: 0 16px;
  border-radius: 1.5rem;
  border-style: solid;
  border-color: white;
  box-shadow: 11px 6px 20px 9px rgba(0, 0, 0, 0.4);
  z-index: 2;
  @media (max-width: 768px) {
    min-width: 100%;
  }
`;
const EmptyPlaylistMessage = styled.div`
  padding: 16px;
  text-align: center;
  color: #718096;
  font-size: 1.2rem;
  margin-top: 20px;
`;
const ContentContainer = styled.div`
  background-color: #edf2f7;
  padding: 16px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e2e8f0;
  height: 10%;
`;

const LyricsContainer = styled.div`
  border-top: 1px solid #e2e8f0;
  background-color: #edf2f7;
  padding: 1px;
  width: 90%;
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LyricLine = styled.div`
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const Audio = styled.audio``;

const Button = styled.button`
  color: #718096;
  margin-right: 1px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  &:hover {
    color: #3182ce;
  }

  @media (max-width: 768px) {
    padding: 0.1rem;
  }
`;

const SongImage = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
`;

const SongTitle = styled.p`
  margin: 12px 0;
  color: #4a5568;
  font-weight: bold;
`;

const RangeInput = styled.input`
  width: 10rem;
  @media (max-width: 768px) {
    width: 5rem;
  }
`;

const VolumeInput = styled(RangeInput)`
  width: 120px;
  @media (max-width: 768px) {
    width: 3rem;
  }
`;

const ToggleButtonContainer = styled.div`
  position: fixed;
  bottom: ${(props) => (props.isVisible ? '120px' : '-35px')};
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.2s ease-in-out;
  z-index: 10;
`;

const ToggleButton = styled.button`
  background: #1e90ff;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #7495cd;
    padding: 26px;
  }
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const PcOnly = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const PlaylistContainer = styled.div`
  border-radius: 1.5rem;
  border-style: solid;
  border-color: white;
  padding: 0 16px;
  min-width: 800px;
  border-radius: 30px;
  position: fixed;
  bottom: ${(props) => (props.isVisible ? '170px' : '-100%')};
  height: 60%;
  background-color: #edf2f7;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
  transition: bottom 0.3s ease-in-out;
  z-index: 1;
  overflow-y: auto;
  @media (max-width: 768px) {
    min-width: 90%;
  }
`;

const PlaylistItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;
  &:hover {
    background-color: #e2e8f0;
  }
`;

const PlaylistImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
`;

const PlaylistTitle = styled.div`
  flex: 1;
  color: #4a5568;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  font-size: 1.2rem;
  &:hover {
    color: #c53030;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #e2e8f0;
  padding: 8px;
`;

const TabButton = styled.button`
  background: ${(props) => (props.active ? '#1e90ff' : 'none')};
  border: none;
  color: ${(props) => (props.active ? 'white' : '#4a5568')};
  font-size: 1rem;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 1.5rem;
  &:hover {
    background: #7495cd;
  }
`;

const GlobalPlayer = ({ isVisible, togglePlayer, albums, setAlbums, isPlayModalOpen, setIsPlayModalOpen,fetchAlbums }) => {
  const {
    audioRef,
    isPlaying,
    playlist,
    setPlaylist,
    shuffledPlaylist,
    currentTrackIndex,
    setCurrentTrackIndex,
    isRepeat,
    handlePlayPause,
    handleRepeatToggle,
    currentLyrics,
    setCurrentLyrics,
    handleShuffleToggle,
    isShuffle,
  } = useAudio();

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [displayedLyric, setDisplayedLyric] = useState('가사가 없습니다');
  const [isPlaylistVisible, setPlaylistVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('playlist');

  const handlePrev = () => {
    setCurrentTrackIndex((prevIndex) => {
      if (isShuffle) {
        return prevIndex === 0 ? shuffledPlaylist.length - 1 : prevIndex - 1;
      }
      return prevIndex === 0 ? playlist.length - 1 : prevIndex - 1;
    });
  };

  const handleNext = () => {
    setCurrentTrackIndex((prevIndex) => {
      if (isShuffle) {
        const nextIndex = Math.floor(Math.random() * shuffledPlaylist.length);
        return nextIndex;
      }
      return prevIndex === playlist.length - 1 ? 0 : prevIndex + 1;
    });
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      setCurrentTime(currentTime);

      const currentLyricObj = currentLyrics
        .slice()
        .reverse()
        .find((lyric) => {
          const lyricTime = parseTimeStringToSeconds(lyric.time);
          return currentTime >= lyricTime;
        });
      if (currentLyricObj) {
        setDisplayedLyric(currentLyricObj.text);
      } else {
        setDisplayedLyric('가사가 없습니다');
      }
    }
  };

  const parseTimeStringToSeconds = (timeString) => {
    if (!timeString) return 0;
  const [minutes, seconds] = timeString.split(':').map(Number);
    if (isNaN(minutes) || isNaN(seconds)) return 0;
    return minutes * 60 + seconds;
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e) => {
    if (audioRef.current) {
      audioRef.current.currentTime = (e.target.value / 100) * duration;
    }
  };

  const handleVolumeChange = (e) => {
    const volume = e.target.value / 100;
    setVolume(volume);
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  };

  const togglePlaylist = () => {
    setPlaylistVisible((prev) => !prev);
  };

  const handleRemoveTrack = (index) => {
    setPlaylist((prevPlaylist) => prevPlaylist.filter((_, i) => i !== index));
    if (index === currentTrackIndex) {
      setCurrentTrackIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
    }
  };

  useEffect(() => {
    console.log('GlobalPlayer useEffect 에서 play 실행됨');
    
    const currentTrack = isShuffle ? shuffledPlaylist[currentTrackIndex] : playlist[currentTrackIndex];
  
    // currentTrack이나 audioRef.current가 없는 경우 바로 종료
    if (!audioRef.current || !currentTrack) return;
  
    // 트랙 설정 및 가사 초기화
    audioRef.current.src = `https://skyscribe.co.kr/music${currentTrack.src}`;
    setCurrentLyrics(currentTrack?.lyrics || []); 
    setDisplayedLyric(currentTrack?.lyrics?.[0]?.text || '가사가 없습니다'); 
  
    // 재생 처리
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          // 특정 오류인 경우 무시
          if(error.message.includes('The play() request was interrupted by a new load request')){
            
          }
          else if(error.message.includes('The operation was aborted')){
            
          }
          else{
            setIsPlayModalOpen(true);
            //alert('브라우저 정책 외 오류:'+isPlayModalOpen + error.message);
          }
        });
      }
    }
  }, [currentTrackIndex, isPlaying, isShuffle, playlist, shuffledPlaylist]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [audioRef, currentLyrics]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleEnded = () => {
    if (isRepeat) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      handleNext();
    }
  };

  return (
    <section style={{ position: 'fixed', width: '100%', display: 'flex', justifyContent: 'center', bottom: '0' }}>
      <Audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={handleEnded}
      >
        <source src={`https://skyscribe.co.kr/music` + playlist[currentTrackIndex]?.src} type="audio/mp3" />
      </Audio>
      <PlayerContainer isVisible={isVisible}>
        <div style={{ width: '90%', display: 'flex', fontSize: '1rem', justifyContent: 'space-between' }}>
          <SongTitle>{(isShuffle ? shuffledPlaylist[currentTrackIndex] : playlist[currentTrackIndex])?.title || 'No Song'}</SongTitle>
          <section style={{ alignContent: 'center', marginTop: '9px' }}>
            <Button onClick={togglePlaylist}>
              <FaListUl />
            </Button>
          </section>
        </div>
        <LyricsContainer>
          <SongImage
            src={
              (isShuffle ? shuffledPlaylist[currentTrackIndex] : playlist[currentTrackIndex])?.image
                ? 'https://skyscribe.co.kr/images' + (isShuffle ? shuffledPlaylist[currentTrackIndex] : playlist[currentTrackIndex]).image
                : emptyPlaylistImage
            }
            alt="Current Song Art"
          />
          <LyricLine>{displayedLyric}</LyricLine>
          <PcOnly style={{ width: '120px' }}> -</PcOnly>
        </LyricsContainer>
        <ContentContainer>
          <PcOnly style={{ width: '20%' }}>-</PcOnly>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={handlePrev}>⏮</Button>
            <Button onClick={handlePlayPause}>{isPlaying ? '⏸' : '⏯'}</Button>
            <Button onClick={handleNext}>⏭</Button>
            <div style={{ display: 'flex', alignItems: 'center', width: '20%' }}>
              <span style={{ color: '#718096', marginRight: '8px' }}>{formatTime(currentTime)}</span>
              <RangeInput type="range" onChange={handleSeek} value={(currentTime / duration) * 100 || 0} />
              <span style={{ color: '#718096', marginLeft: '8px' }}>{formatTime(duration)}</span>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button style={{ marginTop: isShuffle ? '7px' : '0px' }} onClick={handleShuffleToggle}>
              {isShuffle ? <FaRandom /> : '🔀'}
            </Button>
            <Button onClick={handleRepeatToggle}>{isRepeat ? '🔁' : '🔂'}</Button>
            <VolumeInput type="range" value={volume * 100} onChange={handleVolumeChange} />
          </div>
        </ContentContainer>
      </PlayerContainer>
      <ToggleButtonContainer isVisible={isVisible}>
        <ToggleButton onClick={() => { togglePlayer(); setPlaylistVisible(false); }}>
          {isVisible ? <FaChevronDown /> : <FaChevronUp />}
        </ToggleButton>
      </ToggleButtonContainer>
      <PlaylistContainer isVisible={isPlaylistVisible}>
        <TabContainer>
          <TabButton active={activeTab === 'playlist'} onClick={() => setActiveTab('playlist')}>
            현재 재생중
          </TabButton>
          <TabButton active={activeTab === 'myalbum'} onClick={() => setActiveTab('myalbum')}>
            마이앨범
          </TabButton>
        </TabContainer>
        {activeTab === 'playlist' && (
  <>
    {playlist.length === 0 ? (
      <EmptyPlaylistMessage>플레이리스트를 추가하세요 ~</EmptyPlaylistMessage>
    ) : (
      playlist.map((track, index) => (
        <PlaylistItem key={index}>
          <PlaylistImage
            src={`https://skyscribe.co.kr/images` + (track.image || emptyPlaylistImage)}
            alt="Playlist Item Art"
          />
          <PlaylistTitle onClick={() => setCurrentTrackIndex(index)}>
            {track.title}
          </PlaylistTitle>
          <RemoveButton onClick={() => handleRemoveTrack(index)}>
            <FaTrashAlt />
          </RemoveButton>
        </PlaylistItem>
      ))
    )}
  </>
)}
        {activeTab === 'myalbum' && (
          <div>
            <MyAlbum
              togglePlayer={togglePlayer}
              setPlaylistVisible={setPlaylistVisible}
              fetchAlbums={fetchAlbums}
              albums={albums}
              setAlbums={setAlbums}
            />
          </div>
        )}
      </PlaylistContainer>
    </section>
  );
};

export default GlobalPlayer;
