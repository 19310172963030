import React, { useState, useEffect } from 'react';
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Navigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2d3748;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.4);
`;

const ButtonLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: #fff;
  background-color: #46b5bd;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 5px;
  height:1.5rem;
  &:hover {
    background-color: #369ca0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${({ disabled }) => (disabled ? '#b9b8c38f' : '#46b5bd')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const TableContainer = styled.div`
  width: 90%;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 8px rgba(0, 0, 0, 0.4);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const TableTitleHeader = styled.th`
  flex: 0.2;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    flex: 0.2;
  }
`;
const TableContentHeader = styled.th`
  flex: 0.6;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    flex: 0.8;
  }
`;

const TableinVisubleHeader = styled.th`
  flex: 0.05;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TableinVisubleDateHeader = styled.th`
  flex: 0.1;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TableNameCell = styled.td`
  flex: 0.2;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    flex: 0.2;
  }
`;
const TableTitleCell = styled.td`
  flex: 0.6;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    flex: 0.8;
  }
`;
const TableinVisubleDateCell = styled.td`
  flex: 0.1;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TableinVisubleCell = styled.td`
  flex: 0.05;
  text-align: left;
  padding: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const PageingNavigater = ({ totalPosts, setCurrentPage, currentPage, postsPerPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const getPageNumbers = () => {
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(startPage + 4, totalPages);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <PaginationContainer>
      <PaginationButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
        이전
      </PaginationButton>
      {getPageNumbers().map((page) => (
        <PaginationButton key={page} onClick={() => setCurrentPage(page)} disabled={currentPage === page}>
          {page}
        </PaginationButton>
      ))}
      <PaginationButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
        다음
      </PaginationButton>
    </PaginationContainer>
  );
};

const PostTable = ({ post, idx, onClick }) => {
  return (
    <TableRow onClick={onClick}>
      <TableinVisubleCell>{idx}</TableinVisubleCell>
      <TableNameCell>{post.user_name}</TableNameCell>
      <TableTitleCell>{post.title}</TableTitleCell>
      <TableinVisubleDateCell>{post.datetime}</TableinVisubleDateCell>
    </TableRow>
  );
};

export default function List() {
  let navigate = useNavigate();
  const { isLoggedIn, user } = useAuth();
  const [get_DataList, set_DataList] = useState([]);

  const [postsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
    } else {
      getItemList(currentPage, postsPerPage);
    }
  }, [isLoggedIn, currentPage]);

  const getItemList = async (page, limit) => {
    try {
      const response = await fetch(`api/req_itemList?page=${page}&limit=${limit}`, {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_DataList(data.posts);
      setTotalPosts(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Container>
      <Header>
        <ButtonLink to={'/WriteEditer'} style={{ marginLeft: '20px', marginRight: '25px' }}>글쓰기</ButtonLink>
        <div style={{ display: 'flex', width: '200px', flexDirection: 'row' }}>
          <ButtonLink to={'/List'}>리스트</ButtonLink>
          <ButtonLink to={'/Card'}>카드</ButtonLink>
        </div>
      </Header>
      <PageingNavigater
        totalPosts={totalPosts}
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
      />
      <TableContainer>
        <Table>
          <thead>
            <TableRow as="tr">
              <TableinVisubleHeader>번호</TableinVisubleHeader>
              <TableTitleHeader>작성자</TableTitleHeader>
              <TableContentHeader>제목</TableContentHeader>
              <TableinVisubleDateHeader>일자</TableinVisubleDateHeader>
            </TableRow>
          </thead>
          <tbody>
            {get_DataList.map((post, idx) => (
              <PostTable
                key={post.id}
                post={post}
                idx={idx + 1}
                onClick={() => navigate(`/PostViewer`, { state: post })}
              />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
