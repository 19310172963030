import React, { createRef, useState, useEffect } from 'react';
import session from 'express-session';
import ReactDOM from 'react-dom';
import { useLocation ,useNavigate} from 'react-router-dom';
import HtmlEditer from '../../components/HtmlEditer';
import './style.css';


export default function UpdateEditer(상속) {
const { state } = useLocation();
  const navigate=useNavigate();
  const [전송양식, 전송양식_수정] = useState({ "item_id": state.item_id, "item_file": state.item_file,"user_name":state.user_name });
  const [카테고리, 카테고리_수정] = useState([{NOTICE_CATEGORY:'설교요약'},{NOTICE_CATEGORY:'D형QT'},{NOTICE_CATEGORY:'간증문'},{NOTICE_CATEGORY:'독후감'},{NOTICE_CATEGORY:'기도문'}]);
  const [카테고리_선택값, 카테고리_선택값_수정] = useState(상속.category);
  const [공개여부, 공개여부_수정] = useState(state.is_public);
    useEffect(() => { // 폼로드와 비슷한 개념
    }, []);

   

    const 수정 = (전송양식) => {
    fetch(`api/req_edit_Item`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(전송양식)
    }
    ).then(res => res.json()).then(navigate('/')).catch(err => alert('전송 요청' + err));
    }
    return (
      <div style={{ backgroundColor:'#fff' , margin:'10px' , borderStyle:'solid' , borderColor:'silver' }}>
        <div style={{ padding:'30px' }}>
             <section style={{ height:'38px',display:'flex' }}>
                  <input 
                         placeholder='작성자'
                         style={{width:'33.33%',borderRadius:'5px',borderWidth:'1px', padding:'0.5rem' ,borderColor:'silver'}}
                         type='text' 
                         value={전송양식.user_name}
                         onChange={(ev) => 전송양식_수정({ ...전송양식, user_name: ev.target.value })}>
                  </input>
                  <select 
                          placeholder='카테고리'
                          style = {{ width:'33.33%', padding:'0.5rem' ,borderColor:'silver' }}
                          value = { 카테고리_선택값 }
                          defaultValue = { 카테고리_선택값 }
                          onChange={(e) => 카테고리_선택값_수정(e.target.value)}
                          type='text'>
                          {카테고리.map((항목, i) => (<option value={항목.NOTICE_CATEGORY} key={i}>
                          {항목.NOTICE_CATEGORY}
                          </option>))}
                  </select>
                  <select
                          style={{ width:'33.33%',padding:'0.5rem' , borderColor:'silver'}}
                          placeholder='공개범위'
                          value={ 공개여부 }
                          onChange={(ev) => 공개여부_수정(ev.target.value)}
                          type='text'>
                          <option>공개</option>
                          <option>비공개</option>
                  </select>
          </section>
          <div id='EditerBox'
               style={{ minHeight: '300px', marginTop:'30px' }}>
              <HtmlEditer 
                          Submit = {수정}
                          RequestFrom = {전송양식}
                          SelectedCategory = {카테고리_선택값}
                          IsPublic = {공개여부}
                          Base = {state}
                          />
          </div>
        </div>
      </div>
    )
}