import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { FaPlay, FaPlus, FaShareSquare } from 'react-icons/fa';
import { useAudio } from '../../context/AudioContext';
import { useAuth } from '../../context/AuthContext';
import PlaylistModal from './PlaylistModal';
import emptyPlaylistImage from '../../images/empty_playlist.webp';
import PlayModal from './PlayModal'; // 새로운 PlayModal 컴포넌트 추가

export default function Recitation({ fetchAlbums,isPlayModalOpen, setIsPlayModalOpen }) {
  const [selectedMenu, setSelectedMenu] = useState('DiscipleTraining');
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [songs, setSongs] = useState([]);
  const { handlePlayNow, handleAddToPlaylist,shuffledPlaylist,isShuffle, playlist, currentTrackIndex, currentLyric } = useAudio();
  const { isLoggedIn } = useAuth();
  const [albumTrigger, setAlbumTrigger] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlbumForModal, setSelectedAlbumForModal] = useState(null);
  const [songToAdd, setSongToAdd] = useState(null);
  const { songId: paramSongId } = useParams(); // URL 파라미터에서 songId 추출
  const [songId, setSongId] = useState(paramSongId); // 상태 변수로 songId 관리
  const [modalTrack,setModalTrack] = useState(undefined);
  
  const navigate = useNavigate(); // URL 변경을 위해 useNavigate 사용
  useEffect(() => {
    console.log('셔플모드 변경');
  }, [shuffledPlaylist,isShuffle]);
  useEffect(() => {
    fetch('/api/albums', { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        setAlbums(data);
        if (data.length > 0) {
          setSelectedAlbum(data[0].id);
        }
      })
      .catch(error => console.error('Error fetching albums:', error));
  }, []);

  useEffect(() => {
    if (selectedAlbum) {
      fetch(`/api/albums/${selectedAlbum}/songs`, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
          console.log('Fetched songs:', data); // 데이터가 제대로 정렬되어 오는지 확인
          setSongs(data);
          setSelectedMenu('DiscipleTraining');
        })
        .catch(error => console.error('Error fetching songs:', error));
    }
  }, [selectedAlbum, albumTrigger]);

  useEffect(() => {
    console.log('선택곡재생');
    if (songId) {
      setModalTrack(songId);
      fetch(`/api/songs/${songId}`, { credentials: 'include' })
        .then(response => response.json())
        .then(song => {
          handlePlayNow(song);
          setSongId(undefined);
        })
        .catch(error => {
          if(error.message.includes('The play() request was interrupted by a new load request')){
            
          }
          else if(error.message.includes('The operation was aborted')){
            
          }
          else{
            setIsPlayModalOpen(true);
            //alert('브라우저 정책 외 오류:'+isPlayModalOpen + error.message);
          }
        });
    }
  }, [songId, handlePlayNow]);
  
  const handlePlayClick = (songId) => {
    fetch(`/api/songs/${songId}`, { credentials: 'include' })
      .then(response => response.json())
      .then(song => {
        setIsPlayModalOpen(false); // 모달 닫기
        handlePlayNow(song);
        navigate('/'); // URL을 초기화하여 기본 경로로 설정
      })
      .catch(error => console.error('Error playing song:', error));
  };

  const plusCount = (song)=>{
    var songId= song.id;
  fetch(`/api/songs/${songId}/hit`, {
    method: 'POST',
    credentials: 'include' // to include session cookies
  })
    .then(response => response.text())
    .then(data => console.log(data))
    .catch(error => console.error('Error:', error));
  }

  const handleAlbumSelect = (albumId) => {
    setSelectedAlbum(albumId);
    setAlbumTrigger(prev => prev + 1);
  };

  const handleAddToAlbum = (song) => {
    if (isLoggedIn) {
      setSongToAdd(song);
      setSelectedAlbumForModal(null);
      setIsModalOpen(true);
    } else {
      alert('로그인이 필요합니다.');
    }
  };

  const handleShareSong = async (song) => {
    try {
      const response = await fetch('/api/share', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ songId: song.id }),
      });

      const data = await response.json();
      const shareableLink = data.url;

      alert(`공유 링크 : ${shareableLink}`);
      navigator.clipboard.writeText(shareableLink);
    } catch (error) {
      console.error('Error generating shareable link:', error);
      alert('Could not generate shareable link. Please try again later.');
    }
  };

  const handleConfirmAddToAlbum = (song) => {
    if (selectedAlbumForModal && song) {
      fetch(`/api/add_album_songs`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          albumId: selectedAlbumForModal,
          songName: song.display,
          songId: song.id
        }),
      })
        .then(response => response.json())
        .then(data => {
          setIsModalOpen(false);
          alert('노래가 앨범에 추가되었습니다.');
          fetchAlbums();
        })
        .catch(error => console.error('Error adding song to album:', error));
    } else {
      alert('앨범을 선택하세요.');
    }
  };

  const handleSongClick = (song) => {
    console.log('handleSongClick  호출됨');
    handlePlayNow(song); // song 객체에 가사 정보 포함
    plusCount(song);
    navigate('/'); // URL을 초기화하여 기본 경로로 설정
  };

  return (
    <Container>
      <SidebarContainer>
        <TabsContainer>
          <Tab active={selectedMenu === 'BibleRecitation'} onClick={() => setSelectedMenu('BibleRecitation')}>
            앨범
          </Tab>
          <Tab active={selectedMenu === 'DiscipleTraining'} onClick={() => setSelectedMenu('DiscipleTraining')}>
            음악
          </Tab>
        </TabsContainer>
        <SubMenu
          selectedMenu={selectedMenu}
          albums={albums}
          setSelectedAlbum={handleAlbumSelect}
          selectedAlbum={selectedAlbum}
          onMusicSelect={handleAddToPlaylist}
          onPlayNow={handlePlayNow}
          onAddToAlbum={handleAddToAlbum}
          handleShareSong={handleShareSong}
          handleSongClick={handleSongClick} // 곡 클릭 이벤트 핸들러 추가
        />
      </SidebarContainer>
      <ContentContainer>
        <SongInfo currentTrack={isShuffle? shuffledPlaylist[currentTrackIndex]:playlist[currentTrackIndex]} currentLyric={currentLyric} />
      </ContentContainer>
      <PlaylistModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isLoggedIn={isLoggedIn}
        selectedAlbum={selectedAlbumForModal}
        setSelectedAlbum={setSelectedAlbumForModal}
        onConfirm={handleConfirmAddToAlbum}
        song={songToAdd}
      /> 
      <PlayModal
      isOpen={isPlayModalOpen}
      onClose={() => setIsPlayModalOpen(false)}
      onPlayClick={handlePlayClick}
      modalTrack={modalTrack}
      setModalTrack={setModalTrack}
      songId = {songId}
    />
    </Container>
  );
}

const SongInfo = ({ currentTrack, currentLyric }) => {
  const [currentLine, setCurrentLine] = useState('');

  useEffect(() => {
    if (currentLyric && currentLyric.length > 0) {
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const nextLine = currentLyric.find(lyric => lyric.time <= currentTime);
        if (nextLine) {
          setCurrentLine(nextLine.text);
        }
      }, 1000); // 1초마다 체크

      return () => clearInterval(interval);
    }
  }, [currentLyric]);
  

  return (
    <SongInfoContainer>
      <SongDetailContainer>
        <section style={{ alignItems: 'center',display:'flex',flexDirection:'column'}}>
          <SongLargeImage src={currentTrack?.image ? 'https://skyscribe.co.kr/images' + currentTrack.image : emptyPlaylistImage} alt="Album Art" />
          <MusicTitle>{currentTrack?.display || '곡을 선택하세요 '}</MusicTitle>
          <p>{currentTrack?.title}</p>
          <PcOnly>{currentTrack?.hit}</PcOnly>
        </section>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <p>{currentLine}</p>
          <LyricsList>
          {currentTrack?.lyrics.slice(1).map((lyric) => (
              <LyricItem key={lyric.id}>
                {lyric.text}
              </LyricItem>
            ))}
          </LyricsList>
        </div>
      </SongDetailContainer>
    </SongInfoContainer>
  );
};

const SubMenu = ({ selectedMenu, albums, setSelectedAlbum, selectedAlbum, onMusicSelect, onPlayNow, onAddToAlbum, handleShareSong, handleSongClick }) => {
  const [currentAlbum, setCurrentAlbum] = useState([]);
  useEffect(() => {
    if (selectedAlbum) {
      fetch(`/api/albums/${selectedAlbum}/songs`, { credentials: 'include' })
        .then(response => response.json())
        .then(data => setCurrentAlbum(data))
        .catch(error => console.error('Error fetching songs:', error));
    }
  }, [selectedAlbum]);
 

  const handlePlayAll = () => {
    currentAlbum.forEach(song => {
      onMusicSelect(song);
    });
    if (currentAlbum.length > 0) {
      onPlayNow(currentAlbum[0]);
    }
  };

  const AlbumImage = styled.img`
    width: 70px;
    margin-right: 16px;

    @media (max-width: 768px) {
      margin-right: 8px;
    }
  `;

  const menuContent = {
    DiscipleTraining: (
      <div>
        <p>재생 목록</p>
        <hr />
        <SongList>
          <PlayButton style={{ width: '100%' }} onClick={handlePlayAll}>
            전체 재생
          </PlayButton>
          {currentAlbum.map(song => (
            <SongItem key={song.title}>
              {song.display}
              <div>
                <PlayButton onClick={() => handleSongClick(song)}>
                  <FaPlay />
                </PlayButton>
                <AddButton onClick={() => onAddToAlbum(song)}>
                  <FaPlus />
                </AddButton>
                <ShareButton onClick={() => handleShareSong(song)}>
                  <FaShareSquare />
                </ShareButton>
              </div>
            </SongItem>
          ))}
        </SongList>
      </div>
    ),
    BibleRecitation: (
      <div>
        <SongList>
          {albums.map(album => (
            <SongItem key={album.id} onClick={() => setSelectedAlbum(album.id)} style={{ display: (album.song_count === 0 ? 'none' : 'flex') }}>
              <AlbumImage src={`https://skyscribe.co.kr/images` + album.album_img_src} alt={album.album_name} />
              <section style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-end', textAlign: 'right' }}>
                <label>{album.album_name}</label>
                <label style={{ fontSize: '0.7rem' }}>전체:{album.song_count}곡</label>
              </section>
            </SongItem>
          ))}
        </SongList>
      </div>
    ),
  };

  return <SubMenuContainer>{menuContent[selectedMenu]}</SubMenuContainer>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #2d3748;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SidebarContainer = styled.div`
  background-color: #2d3748;
  color: white;
  display: flex;
  flex-direction: column;
  min-width: 400px;
`;

const SubMenuContainer = styled.div`
  background-color: #1a202c;
  padding: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 81.9%;

  @media (max-width: 768px) {
    width: 100%;
    order: -1;
    height: 45%;
  }
`;

const MusicTitle = styled.label`
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-top: 0.0rem;
  }
`;

const SongImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  padding: 20px;
  margin: 2rem 5rem;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
`;

const SongInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const SongDetailContainer = styled.div`
  display: flex;
  align-items: normal;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const SongLargeImage = styled(SongImage)`
  width: 320px;
  height: 320px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  margin: 2rem;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const PcOnly = styled.p`
  display: flex;
    @media (max-width: 768px) {
    display: none;
  }
`;

const SongList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SongItem = styled.li`
  margin: 8px 0;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #2d3748;
  color: white;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a5568;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: #2d3748;
  padding: 16px;
`;

const Tab = styled.button`
  background-color: ${({ active }) => (active ? '#4a5568' : '#2d3748')};
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background-color: #4a5568;
  }
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #4caf50;
  font-size: 15px;

  &:hover {
    color: #45a049;
  }
`;

const AddButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #3182ce;
  font-size: 15px;
  margin-left: 2px;

  &:hover {
    color: #2b6cb0;
  }
`;
const ShareButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #e152ce;
  font-size: 15px;
  margin-left: 2px;

  &:hover {
    color: #2b6cb0;
  }
`;
// Define the styled component for the lyrics list
const LyricsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: 'Arial, sans-serif';
  font-size: 12px;
  color: #333;
  line-height: 1.6;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  // Media query to hide the lyrics list on screens smaller than 768px (typical mobile screen size)
  @media (max-width: 768px) {
    display: none;
  }
`;

// Define the styled component for each lyric item
const LyricItem = styled.li`
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
  }
`;