import React,{useState,useEffect,useContext} from 'react';
import { useAuth } from "../../context/AuthContext";
import {useNavigate,useLocation , Navigate, Link } from 'react-router-dom';
import { RegisterForm, Login } from "../../components/Login";

export default function PostViewer(post){
    const { state } = useLocation();
    const { isLoggedIn, user } = useAuth();
    let navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate(`/login`);
      } 
    }, [isLoggedIn]);
    
        return (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            padding: '20px',
            justifyContent: 'center'
          }}>
              <Post post={state} />
          </div>
        );


};
const Post = ({ post }) => {
  const { isLoggedIn, user } = useAuth();
  let navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/login`);
    } 
  }, [isLoggedIn]);
  
  const handleDelete = async () => {
    console.log(post.item_id)
    try {
      const response = await fetch(`api/delete_post/${post.item_id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        alert('Post deleted successfully');
        navigate('/'); // Navigate to home or another appropriate page
      } else {
        alert('Failed to delete the post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      alert('An error occurred while deleting the post');
    }
  };
  console.log(JSON.stringify(post));
    return (
      <div style={{
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        width: '90%',
        minHeight:'30rem',
        borderRadius: '5px',
        padding: '10px 25px',
        backgroundColor: 'white',
        display:'flex',
        justifyContent:'center',
        margin: '10px'
      }}> 
      <main style={{
        backgroundColor: 'white',
        display:'flex',
        flexDirection:'column',
        width:'100%',
      }}> 
        <header style={{display:'flex',justifyContent:'space-between'}}>
          <div  style={{display:'flex',justifyContent:'flex-start'}}>
        <label style={{fontWeight:'bold',fontSize:'0.8rem'}} onClick={()=>navigate(`/Update`, { state:  post  })}>[작성자:{post.user_name}]</label>
        <label style={{fontWeight:'bold',fontSize:'0.8rem'}} onClick={()=>navigate(`/Update`, { state:  post  })}>[작성일:{post.datetime}]</label>
          </div>
          <div  style={{display:'flex',justifyContent:'flex-start'}}>
            {user?.id.toLowerCase() === 'dexterity' || post.user_name.toLowerCase() === user?.id.toLowerCase() ? (
              <>
                <label 
                  style={{ fontWeight: 'bold', fontSize: '0.8rem' }} 
                  onClick={() => navigate(`/Update`, { state: post })}
                >
                  [수정]
                </label>
                <label 
                  style={{ fontWeight: 'bold', fontSize: '0.8rem' }} 
                  onClick={() => handleDelete()}
                >
                  [삭제]
                </label>
              </>
            ) : ''}
        </div>
        </header>
        <hr></hr>
        <h2 style={{ color: '#333',textAlign:'left' }}>{post.title}</h2>
        <span  style={{ color: '#333',textAlign:'left' }} dangerouslySetInnerHTML={{ __html: post.content }}></span>
        </main>
      </div>
     
    );
  };

  
  
  
  
  
  





