import React, { createContext, useState, useRef, useContext, useEffect } from 'react';

const AudioContext = createContext();

export const useAudio = () => useContext(AudioContext);

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [shuffledPlaylist, setShuffledPlaylist] = useState([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isRepeat, setIsRepeat] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  const [currentLyrics, setCurrentLyrics] = useState([]);

  // 배열을 셔플하는 유틸리티 함수
  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    // 셔플이 활성화될 때마다 셔플된 플레이리스트를 업데이트
    if (isShuffle) {
      setShuffledPlaylist(shuffleArray(playlist));
    } else {
      setShuffledPlaylist([]);
    }
  }, [playlist, isShuffle]);

  useEffect(() => {
    const currentTrack = isShuffle ? shuffledPlaylist[currentTrackIndex] : playlist[currentTrackIndex];

    if (currentTrack && audioRef.current) {
      audioRef.current.src = 'https://skyscribe.co.kr/music' + currentTrack.src;
      if (isPlaying) {
        audioRef.current.play().catch((error) => {
          console.error('재생오류:', error);
        });
      }
    }

    // Media Session API 메타데이터 설정
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: currentTrack?.title || 'No Title',
        artist: currentTrack?.artist || 'Unknown Artist',
        album: currentTrack?.album || 'Unknown Album',
        artwork: currentTrack?.artwork || [], // 이미지가 있다면 설정합니다.
      });
    }
  }, [currentTrackIndex, playlist, shuffledPlaylist, isPlaying, isShuffle]);

  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        handlePreviousTrack();
      });
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        handleNextTrack();
      });
    }
  }, [currentTrackIndex, playlist, shuffledPlaylist, isPlaying, isShuffle]);

  const handleRemoveFromPlaylist = (index) => {
    setPlaylist((prevPlaylist) => prevPlaylist.filter((_, i) => i !== index));
    if (isShuffle) {
      setShuffledPlaylist((prevShuffled) => prevShuffled.filter((_, i) => i !== index));
    }
    if (currentTrackIndex === index) {
      setCurrentTrackIndex(0);
      if (playlist.length > 1) {
        audioRef.current.src = playlist[0].src;
        audioRef.current.play().catch((error) => {
          console.error('Error playing the audio:', error);
        });
      }
    } else if (currentTrackIndex > index) {
      setCurrentTrackIndex(currentTrackIndex - 1);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play().catch((error) => {
        console.error('Error playing the audio:', error);
      });
      setIsPlaying(true);
    }
  };

  const handlePlay = () => {
    console.log('AudionContex=>handlePlay();');
    audioRef.current.play().catch((error) => {
      console.error('Error playing the audio:', error);
    });
    setIsPlaying(true);
  };



  const handlePlayNow = (song) => {
    console.log("Playing song:", song); // 디버깅을 위한 로그
    const index = playlist.findIndex(item => item.src === song.src);
    if (index !== -1) {
      setCurrentTrackIndex(index);
    } else {
      setPlaylist((prevPlaylist) => [song, ...prevPlaylist]);
      setCurrentTrackIndex(0);
    }
    setCurrentLyrics(song.lyrics || []); // 가사 설정

    audioRef.current.src = 'https://skyscribe.co.kr/music' + song.src;
    audioRef.current.load();
    handlePlay();
  };

  const handleRepeatToggle = () => {
    setIsRepeat(!isRepeat);
  };

  const handleShuffleToggle = () => {
    if (!isShuffle) {
      const shuffled = shuffleArray(playlist);
      setShuffledPlaylist(shuffled);
      const currentTrackInShuffle = shuffled.findIndex(
        (track) => track.src === playlist[currentTrackIndex].src
      );
      setCurrentTrackIndex(currentTrackInShuffle === -1 ? 0 : currentTrackInShuffle);
    } else {
      const currentTrackInOriginal = playlist.findIndex(
        (track) => track.src === shuffledPlaylist[currentTrackIndex].src
      );
      setCurrentTrackIndex(currentTrackInOriginal === -1 ? 0 : currentTrackInOriginal);
    }
    setIsShuffle((prev) => !prev);
  };

  const handleAddToPlaylist = (song) => {
    setPlaylist((prevPlaylist) => {
      if (prevPlaylist.find((track) => track.src === song.src)) {
        return prevPlaylist;
      }
      const newPlaylist = [...prevPlaylist, song];
      setShuffledPlaylist(shuffleArray(newPlaylist)); // 셔플된 플레이리스트 업데이트
      return newPlaylist;
    });
  };

  const handlePreviousTrack = () => {
    setCurrentTrackIndex((prevIndex) => {
      const playlistToUse = isShuffle ? shuffledPlaylist : playlist;
      return prevIndex === 0 ? playlistToUse.length - 1 : prevIndex - 1;
    });
  };

  const handleNextTrack = () => {
    if (isShuffle) {
      const nextIndex = Math.floor(Math.random() * shuffledPlaylist.length);
      setCurrentTrackIndex(nextIndex);
    } else {
      setCurrentTrackIndex((prevIndex) => {
        return prevIndex === playlist.length - 1 ? 0 : prevIndex + 1;
      });
    }
  };

  return (
    <AudioContext.Provider
      value={{
        audioRef,
        isPlaying,
        setIsPlaying,
        playlist,
        setPlaylist,
        shuffledPlaylist, // shuffledPlaylist 추가
        currentTrackIndex,
        setCurrentTrackIndex,
        isRepeat,
        setIsRepeat,
        isShuffle,
        setIsShuffle,
        currentLyrics,
        setCurrentLyrics,
        handlePlayPause,
        handlePlay,
        handlePlayNow,
        handleRepeatToggle,
        handleShuffleToggle,
        handleAddToPlaylist,
        handleRemoveFromPlaylist,
        handlePreviousTrack,
        handleNextTrack,
      }}
    >
      <audio ref={audioRef} />
      {children}
    </AudioContext.Provider>
  );
};