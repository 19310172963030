import React, { useState, useEffect } from 'react';
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation, Link ,Navigate} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #2d3748;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ButtonLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: #fff;
  background-color: #46b5bd;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 5px;
  height:1.5rem;
  &:hover {
    background-color: #369ca0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 0;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  padding: 8px 16px;
  background-color: ${({ disabled }) => (disabled ? '#b9b8c38f' : '#46b5bd')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  justify-content: center;
`;

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 300px;
  max-height: 15rem;
  height: 14rem;
  border-radius: 5px;
  padding: 20px;
  background-color: white;
  margin: 10px;
  cursor: pointer;
`;

const CardTitle = styled.h2`
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardContent = styled.div`
  color: #666;
`;

const SermonTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 3em;
`;

const PageingNavigater = ({ totalPosts, setCurrentPage, currentPage, postsPerPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  const getPageNumbers = () => {
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(startPage + 4, totalPages);
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <PaginationContainer>
      <PaginationButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
        이전
      </PaginationButton>
      {getPageNumbers().map((page) => (
        <PaginationButton key={page} onClick={() => setCurrentPage(page)} disabled={currentPage === page}>
          {page}
        </PaginationButton>
      ))}
      <PaginationButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
        다음
      </PaginationButton>
    </PaginationContainer>
  );
};

export default function CardView() {
  let navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn,user } = useAuth();
  const [get_DataList, set_DataList] = useState([]);
  const [postsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);


  useEffect(() => {
    getItemList(currentPage, postsPerPage);
  }, [currentPage, location]);

  const getItemList = async (page, limit) => {
    try {
      const response = await fetch(`api/req_itemList?page=${page}&limit=${limit}`, {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      set_DataList(data.posts);
      setTotalPosts(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      
    }
    else{
      navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
    }
  }, [isLoggedIn]); 
  return (
    <Container>
      <Header>
      <ButtonLink to={'/WriteEditer'} style={{marginLeft:'20px',marginRight:'25px'}}>글쓰기</ButtonLink>
        <div style={{display:'flex',width:'200px',flexDirection:'row'}}>
          <ButtonLink to={'/List'}>리스트</ButtonLink>
          <ButtonLink to={'/Card'}>카드</ButtonLink>
        </div>
      </Header>
      <PageingNavigater
        totalPosts={totalPosts}
        currentPage={currentPage}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
      />
      <CardContainer>
        {get_DataList.map(post => (
          <Card key={post.id} onClick={() => navigate(`/PostViewer`, { state: post })}>
            <CardTitle>{post.title}</CardTitle>
            <hr />
            <CardContent>
              <SermonTitle>{post.sermon_title}</SermonTitle>
              <p>작성자: {post.user_name}</p>
              <p>게시일: {post.datetime}</p>
            </CardContent>
          </Card>
        ))}
      </CardContainer>
    </Container>
  );
}
