import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import styled from 'styled-components';
import logo from '../../images/logo512.png';
import antslogo from '../../images/AntsNestLogo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt,faDownload  } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  background-color: #2d3748;
  position: fixed; /* 헤더를 고정 */
  top: 0;
  left: 0;
  z-index: 1000;
  @media (max-width: 768px) {
    height: 10vh;
  }
`;

const LeftLogo = styled.div`
  display: flex;
  align-items: center;
  padding:2rem;

  img {
    height: 3rem;
    width: 3rem;
  }

  li {
    margin-left: 2rem;
    margin-right: 2rem;
    list-style: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 768px) {
    img {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;

const RightNav = styled.ul`
  width: 40%;
  display: flex;
  justify-content: space-around;
  padding-inline-start: 0;
  align-items: center;
  font-size: 15px;
  
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  display: none;

  @media (min-width: 768px) {
    display: block;
    width: 20%;
    font-size: 16pt;
    border-radius: 15px;
    padding: 10px;
    margin: 1rem;
  }
`;

const BurgerButton = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    right: 36px;
    width: 36px;
    height: 30px;
    cursor: pointer;
    z-index: 1000;

    div {
      width: 100%;
      height: 4px;
      background-color: #fff;
      margin: 6px 0;
      transition: 0.4s;
    }
  }
`;

const SideMenu = styled.div`
  height: 100%;
  width: ${props => (props.isOpen ? '250px' : '0')};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #08457b;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: 0.5s;
  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
  }

  a:hover {
    color: #7f8a9f;
  }

  .closebtn {
    padding: 0px 0px 0px 0px;
    
    color:white;
    font-size: 36px;
    height: 3.5rem;
    width: 3.5rem;
    border-left-style: groove;
    &:hover {
        color: #7f8a9f;
        background-color: silver;
    }
  }
`;

const FloatingButton = styled(Link)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #444;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  z-index: 1000;
  display:none;
  &:hover {
    background-color: #2d3748;
  }

  @media (max-width: 768px) {
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
`;

export default function Navigator({handleInstallClick,isInstallable}) {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentCount, setCurrentCount] = useState(0); // 현재 방문자 수를 저장할 상태
  const systemId = 'skyscribe';
  useEffect(() => {
    updateVisitorCount();
  }, []); // 빈 의존성 배열을 사용하여 컴포넌트 마운트 시에만 호출되도록 설정
  // 당일 방문자 수를 업데이트하고 현재 카운트를 받아오는 함수
  const updateVisitorCount = async () => {
    try {
      const response = await fetch(`api/updateVisitorCount?systemId=${systemId}`, {
        method: 'GET', // GET 메소드 사용
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCurrentCount(data.currentCount); // 응답으로 받은 현재 방문자 수를 상태로 저장
    } catch (error) {
      console.error('Error fetching visitor count:', error);
    }
  };
  const menuRef = useRef(null);

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', { method: 'GET' });

      if (response.ok) {
        logout();
        sessionStorage.removeItem('user');
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container>
      <LeftLogo>
        <Link to={'/'}><label style={{ fontSize: '30px', fontWeight: '800', cursor: 'pointer' }}>Sky Scribe</label></Link>
      </LeftLogo>

      <BurgerButton onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </BurgerButton>

      <SideMenu isOpen={menuOpen} ref={menuRef}>
        <div style={{ alignContent: 'center', position: 'relative', marginBottom: '2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#1a202c' }}>
          <label style={{ fontWeight: '800', cursor: 'pointer', color: 'white', marginLeft: '1.5rem' }}>Sky Scribe</label>
          <a href="javascript:void(0)" className="closebtn" onClick={toggleMenu}>&times;</a></div>
        <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Link to={'/'} onClick={toggleMenu}>성경암송</Link>
          <Link to={'/Bible'} onClick={toggleMenu}>성경읽기</Link>
          <Link to={'/List'} onClick={toggleMenu}>주일설교</Link>
          {isLoggedIn ? (
            <a href="javascript:void(0)" onClick={handleLogout}>로그아웃</a>
          ) : (
            <Link to={'/Login'} onClick={toggleMenu}>로그인</Link>
          )}
          {isInstallable && (<a style={{cursor:'pointer'}} onClick={handleInstallClick}><FontAwesomeIcon icon={faDownload}/> 앱 설치</a>)}
          <div style={{ alignContent: 'center', display: 'flex', position: 'absolute', bottom: '80px', width: '100%', padding: '8px 20px', backgroundColor: '#0b1d34' }}>
        <label style={{alignContent:'center'}}>visitor : {currentCount}</label>
        </div>
        </div>
        <div style={{ alignContent: 'center', display: 'flex', position: 'absolute', bottom: '0px', width: '100%', padding: '8px 20px', backgroundColor: 'black' }}>
          <img src={antslogo} style={{ width: '2.2rem', height: '2.5rem', padding: '0.2rem 0.5rem' }}></img>
          <label style={{ fontSize: '28px', fontWeight: '800', cursor: 'pointer', color: 'white', marginBottom: '0.3rem' }}><a style={{ padding: '5px 13px' }} href="https://antsnest.co.kr">AntsNest</a></label>
        </div>
      </SideMenu>

      <RightNav>
      {isInstallable && (<label style={{cursor:'pointer'}} onClick={handleInstallClick}><FontAwesomeIcon icon={faDownload}/> 앱 설치</label>)}
      
        <li><Link to={'/'}>성경구절</Link></li>
        <li><Link to={'/Bible'}>성경읽기</Link></li>
        <li><Link to={'/List'}>주일설교</Link></li>
        {isLoggedIn ? (
          <label style={{ cursor: 'pointer' }} onClick={handleLogout}><FontAwesomeIcon icon={faSignOutAlt}  /></label>
        ) : (
          <li><Link to={'/Login'}><FontAwesomeIcon icon={faUser}  /></Link></li>
        )}
        <div style={{ alignContent: 'center', display: 'flex', position: 'absolute', bottom: '0px', right: '0%', padding: '0px 5px'}}>
        <label style={{alignContent:'center'}}>visitor : {currentCount}</label>
        </div>
      </RightNav>

      {/* <SearchInput type='text' placeholder="말씀 검색" /> */}

      <FloatingButton to="/WriteEditer">+</FloatingButton>
    </Container>
  );
}