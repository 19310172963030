import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Routes, Route ,BrowserRouter} from "react-router-dom";
import { RecoilRoot } from 'recoil';
import * as serviceWorker from './serviceWorker'; // Service Worker 등록 파일
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
      <BrowserRouter>
    <App />
    </BrowserRouter>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Service Worker 등록
serviceWorker.register();